import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../../components/Header/header"
import Footer from "../../components/Footer/footer"
import * as styles from "../../styles/product-page.module.css"
import ProductDescription from "../../components/ProductDescription/ProductDescription"

const paragraphs = [
    "New Zealand pine sawn lumber is a versatile structural building material well suited for all building system. It is used equally successfully in larger buildings as glulam and for many other structural applications.",
    "New Zealand pine is a preferred material for construction both as sawn lumber and as engineered products such as glulam, plywood and other panel products.",
    "New Zealand pine’s most attractive properties for construction usage are its medium density and uniform grain, which confer good fastening and working properties. New Zealand pine’s strength and stiffness, ease of drying and suitability for treatment with preservatives and fire-retardant chemicals are also advantageous for construction. It is a relatively stable wood and kiln drying further improves its stability.",
    "New Zealand pine is being successfully used for a wide range of interior fittings and fixtures, including windows, doors, frames and jambs, mouldings, stairs, cabinetry and bench tops.",
]

const Construction = () => {
    return (
        <React.Fragment>
            <Header invert={true} />

            <div className={styles.pageTitle}>
                <h1>Construction</h1>
            </div>

            <ProductDescription text={paragraphs}>
                <StaticImage
                    src="../../images/pallet.jpg"
                    alt="Timber pallet"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/home.jpg"
                    alt="Pine wood home"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/interior.jpg"
                    alt="Pine interior"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/structure.jpg"
                    alt="Pine structure"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
            </ProductDescription>

            <Footer />
        </React.Fragment>
    )
}

export default Construction
